<template>
  <div class="home">
    <!--    课程菜单-->
    <el-row class="course-menu m_tb_20">
      <el-col
        :span="24"
        style="
          border-radius: 0.5rem;
          overflow: hidden;
          margin-left: 20px;
          position: absolute;
          right: 0;
        ">
        <el-carousel
          trigger="hover"
          height="270px">
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="index"
            style="background: lightgray">
            <router-link
             :to="{ path: item.hrefUrl }">
              <img
                width="100%"
                height="100%"
               :src="item.bannerUrl"
                style="object-fit: cover"
                alt="banner" />
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <!--内容-->
    <el-row class="flex_row content">
      <el-tabs
        @tab-click="handleClick"
        v-model="activeName">
        <el-tab-pane
          label="热门推荐"
          name="0">
          <div class="box">
            <Course
              v-for="item in hotResourcesList"
              :key="item.id"
              :info="item"></Course>
          </div>
          <el-pagination
            @current-change="current_change"
            :page-size="params.size"
            :background="true"
            layout="total, prev, pager, next"
            :total="params.total"></el-pagination>
        </el-tab-pane>
        <el-tab-pane
          :label="item.name"
          :name="item.id"
          v-for="item in classify"
          :key="item.id">
          <div class="box">
            <Course
              v-for="item in resourcesList"
              :key="item.id"
              :info="item"></Course>
          </div>
          <el-empty v-show="resourcesList.length == 0"></el-empty>
          <el-pagination
            :hide-on-single-page="true"
            @current-change="current_change"
            :page-size="params.size"
            :background="true"
            layout="total, prev, pager, next"
            :total="params.total"></el-pagination>
        </el-tab-pane>
      </el-tabs>
      <div style="position: absolute; right: 0; top: 5px; display: flex">
        <!-- <SearchBox :options="['全部批次', '第一批次']"></SearchBox>
        &nbsp; &nbsp; -->
        <SearchBox
          :options="['浏览量排序', '点赞量排序']"
          @change="sort"></SearchBox>
      </div>
    </el-row>
  </div>
</template>

<script>
import Title from '@/components/8.30/Title';
import SearchBox from '@/components/8.30/SearchBox';
import Course from '@/components/8.30/Course';
import { sessionGet, sessionSet } from '@/utils/local.js';
import {
  getHotResources,
  getPastWonderfulData,
  getResourcesClassifyPage,
  getDataPage,
  getBanner
} from '@/api/8.30';
export default {
  name: 'index',
  components: {
    Title,
    Course,
    SearchBox,
  },
  data: () => {
    return {
      activeName: '',
      params: {
        current: 1,
        size: 12,
        total: 0,
        order: 'desc',
        orderColumn: 'browseNumber',
      },
      resourcesList: [],
      hotResourcesList: [],
      classify: [],
      bannerList: [],
    };
  },
  async created() {
    this.hotResources();
    //查询首页分类
    this.classify = sessionGet('classify');
    //获取banner数据
    const {data:banner}=await getBanner({type:3});
    this.bannerList=banner;
    // this.bannerList = [
    //   {
    //     coverUrl:
    //       this.hotResourcesList.length > 0
    //         ? this.hotResourcesList[0].coverUrl
    //         : require('@/assets/image/bqld.png'),
    //     url: '/More',
    //     query: '',
    //   },
    //   {
    //     coverUrl: require('@/assets/image/xqyg.png'),
    //     url: '/Content',
    //     query: '1702251616474083330',
    //   },
    //   {
    //     coverUrl: require('@/assets/image/zjyq.png'),
    //     url: '/Content',
    //     query: '1702250469562626050',
    //   },
    // ];
  },
  methods: {
    //获取热门资源
    async hotResources() {
      const { data } = await getHotResources(this.params);

      this.hotResourcesList = data.records;
      this.params.total = data.total;
    },

    handleClick() {
      //通过目录id查询资源
      this.params.current = 1;
      if (this.activeName != '0') {
        getDataPage({ contentsId: this.activeName, ...this.params }).then(
          (res) => {
            this.resourcesList = res.data.records;
            this.params.total = res.data.total;
          }
        );
      } else {
        this.hotResources();
      }
    },
    sort(e) {
      this.params.orderColumn = e == '浏览量排序' ? 'browseNumber' : 'linkNum';
      if (this.activeName == '0') {
        this.hotResources();
      }
    },
    current_change(page) {
      this.params.current = page;
      if (this.activeName != '0') {
        getDataPage({ contentsId: this.activeName, ...this.params }).then(
          (res) => {
            this.resourcesList = res.data.records;
            this.params.total = res.data.total;
          }
        );
      } else {
        this.hotResources();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 120rem;
  margin: 1rem auto;
  position: relative;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__item:hover {
  color: #0f4444;
}
::v-deep .el-tabs__item.is-active {
  color: #0f4444;
}
::v-deep .el-tabs__item {
  font-size: 20px;
  font-weight: 700;
  color: #999;
}
::v-deep .el-tabs__active-bar {
  background-color: #0f4444;
  width: 30px !important;
  height: 4px;
}
::v-deep .el-tabs__content {
  width: 120rem;
}
.content {
  .box {
    display: grid;
    grid-template-columns: repeat(4, 290px);
    justify-content: space-between;
    grid-auto-rows: 350px;
  }
}

.course-menu {
  height: 270px;
  position: relative;
}
</style>
